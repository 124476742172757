<template>
  <div id="app" >
    <main-page />
  </div>
</template>

<script>
import MainPage from './components/main'
export default {
  name: 'App',
  components: {
    MainPage
  },
  created(){
    if (this.$workbox) {
      this.$workbox.addEventListener("controlling", () => {
        window.location.reload()
      });
    }
  },
}
</script>

<style>
</style>
